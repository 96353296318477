@import '../../../../styles/_global.variables';

.attachments-row {
  .details-table-row__cell {
    :first-child {
      margin-right: 12px;
    }
  }
}

.event-icon {
  font-size: 20px;
  margin: 0 4px;
}

.employment-panel {
  .add-button {
    background-color: darkorange;
    position: fixed;
    right: 22px;
    bottom: 22px;
    z-index: 9;
  }
}

.employment-contract {
  .mdc-select {
    width: 100%;
  }
}

.employment-edit {
  .days {
    margin-top: 19px;
  }
}
