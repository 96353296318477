@import '../../../styles/_global.variables';

.attendence {
  background-color: white;
  overflow: hidden;
  height: calc(100vh - 48px);

  .BottomRightGrid_ScrollWrapperParent {
    display: flex;
    flex: 1;

    .BottomRightGrid_ScrollWrapper {
      overflow: overlay !important;
    }
  }

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    z-index: 999999999;
    height: 100%;
    min-height: unset;
    width: 100vw;
    min-width: auto;
    background-color: white;

    .custom-card {
      height: calc(100% - 48px);
    }
  }

  .scroll-content {
    overflow: hidden scroll;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .calendar-pager {
    position: sticky;
    top: 0;
    z-index: 9;
    margin-bottom: unset;
    border-top: 4px solid #eee;

    .lock-section {
      position: absolute;
      left: 12px;

      &__inner-wrap {
        display: flex;
        align-items: center;
      }

      .material-icons {
        font-size: 20px;
        margin-right: 6px;
        color: rgba(128, 128, 128, 0.64);
      }

      .title {
        font-size: 13px;
        text-align: left;
        color: rgba(128, 128, 128, 0.64);
      }
    }
  }

  // Temp
  .custom-card {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }

  &-table {
    &--subheader {
      position: sticky;
      top: 48px;
      z-index: 9;
      border-top: 1px solid #eee;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eee;
      height: 50px;
      padding: 8px 16px;
      box-sizing: border-box;

      .title {
        color: #0096d2;
        font-size: 0.875rem;
        font-weight: 500;
      }

      .controls {
        display: flex;
        align-items: center;

        .rmwc-icon {
          color: rgba(0, 0, 0, 0.54);
        }
      }

      .mdc-switch {
        margin-right: 8px;
      }
    }

    &--headline {
      background-color: white;
      border-bottom: 1px solid #eeeeee;
      color: rgba(0, 0, 0, 0.54);
      height: 100%;

      &__cell {
        .calendar--day--holiday {
          background-color: #d50000;
          color: white;
        }
      }
    }

    &__row-cell {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 100%;
      border-right: 1px solid #e9e9e9;
      border-bottom: 1px solid #e9e9e9;
      box-sizing: border-box;
      padding: 4px 0;

      .action-icon {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
      }

      &.data-table__row-cell--overflow {
        .overflow {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          padding-right: 6px;
        }
      }

      .circular-progress-wrap {
        padding: 0 !important;
        height: 20px;
      }

      .mdc-select {
        background-color: transparent;
        border-radius: 0;
        width: 20px;
        height: 28px;
        display: flex;
        justify-content: center;

        &::before {
          display: none;
        }

        .mdc-select__native-control {
          padding: 29px 0 0 0;
          max-height: 29px;
          border-bottom: unset;
        }

        .mdc-select__dropdown-icon {
          right: unset;
          bottom: 0;
          opacity: 0.8;
        }

        .mdc-line-ripple {
          display: none;
        }
      }

      .expected-time {
        .text {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      .text {
        font-size: 12px;
      }

      &.calendar--day--holiday {
        background-color: rgba(213, 0, 0, 0.31);
      }

      &.calendar--day--weekend {
        background-color: #f3f3f3;
      }

      &.calendar--day--today {
        background-color: #0096d2;
        color: white;
      }

      &:not(.person) {
        &:not(.attendence-table--headline__cell) {
          &:hover {
            &::before {
              content: '';
              position: absolute;
              left: 0;
              right: 0;
              top: -9999px;
              bottom: -9999px;
              background-color: rgba(0, 150, 208, 0.45);
              border: 1px solid rgba(0, 150, 208, 0.12);
              pointer-events: none;
              z-index: 1;
            }

            &::after {
              content: '';
              position: absolute;
              left: -9999px;
              right: -9999px;
              top: 0;
              bottom: 0;
              background-color: rgba(0, 150, 208, 0.25);
              border: 1px solid rgba(0, 150, 208, 0.12);
              pointer-events: none;
              z-index: 8;
            }
          }
        }
      }

      &.wireframe {
        width: 100%;
        height: 100%;
        display: block;
        background-color: #fff;
      }

      &.person {
        width: 100%;
        flex-shrink: 0;
        flex: unset;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px 8px;
        box-sizing: border-box;

        .close-icon {
          color: rgba(0, 0, 0, 0.87);
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }
}
