@import '../../../styles/_global.variables';

.product-details-images {
  margin: 8px 16px;

  .primary-text {
    color: rgba(0, 0, 0, 0.54);
  }

  .image-list-product {
    margin-top: 8px;
  }
}

.task-attachment {
  margin: 2px;
  padding: 2px;
  border-radius: 4px;
  border: 2px solid transparent;
  cursor: pointer;

  .support {
    width: 80px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    font-size: 0.8em;
    // margin instead of padding, because line clamp overflow hidden not working in the padding box
    padding: 0;
    margin: 8px 0;
    line-height: 1.3;
  }

  &-selected {
    margin: 2px;
    padding: 2px;
    border-radius: 4px;
    border: 2px solid $dashboard-primary-color;
    cursor: pointer;

    .support {
      width: 80px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
      font-size: 0.8em;
      padding: 0;
      margin: 8px 0;
      line-height: 1.3;
    }
  }
}

.additional-quantity-box {
  display: flex;
}

.additional-quantity-box {
  display: flex;
}

.margin-top-20 {
  margin-top: 20px;
}

.products-details .mdc-dialog__content {
  overflow: hidden;
}
