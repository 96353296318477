.Projects {
  .search-box {
    height: 50px;
    border-top: 4px solid #f1f1f1;
    border-bottom: 4px solid #f1f1f1;
  }
}

.project-list {
  .project-sync {
    border: 1px solid #eee;
    width: min-content;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 12px;
  }

  .data-table {
    &__row {
      border-bottom: 1px solid #eee;
    }
  }

  .wireframe-design {
    .checkbox {
      .wireframe-item {
        width: 20px;
      }
    }

    .wireframe-item {
      background-color: #eee;
      width: 140px;
      height: 20px;
      border-radius: 4px;
    }
  }
}
