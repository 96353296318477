@import '../../../../styles/_global.variables';

.People {
  .mdc-tab-bar {
    border-top: 4px solid #eee;
  }

  .search-box {
    border-top: 4px solid #eee;
  }
}

.search-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  background-color: white;
  color: rgba(0, 0, 0, 0.54);

  &--start-detail {
    display: inline-flex;
  }

  &--input {
    display: inline-flex;
    width: 100%;
    height: 100%;
    padding: 4px;
    border: none;
    font-size: 14px;

    &::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.54) !important;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: rgba(0, 0, 0, 0.54) !important;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: rgba(0, 0, 0, 0.54) !important;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: rgba(0, 0, 0, 0.54) !important;
    }
  }
}

.file-upload-progress {
  padding: 0;
  margin-right: 12px;
}

.People {
  min-height: 100%;

  .data-table {
    &__row {
      padding: 8px 16px;
    }
  }

  .employment-short-details-panel {
    background-color: white;
    width: 250px;
    margin-right: 4px;
    position: sticky;
    display: inline-block;
    vertical-align: top;
    height: calc(100vh - 48px);

    .employe-wrap {
      height: 255px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #eee;
      text-align: center;
      position: relative;
      padding: 6px;
      box-sizing: border-box;

      .back-icon {
        color: rgba(0, 0, 0, 0.54);
        position: absolute;
        top: 6px;
        left: 6px;
        font-size: 28px;
      }

      .job-role {
        font-size: 14px;
        line-height: 24px;
      }

      .employe-avatar {
        width: 100px;
        height: 100px;
        background-color: rgba(0, 0, 0, 0.04);
        color: rgba(0, 0, 0, 0.54);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        margin-bottom: 6px;
      }

      .employe {
        color: $dashboard-primary-color;
      }
    }

    .employe-details {
      .details-group {
        &-header,
        &-row {
          display: flex;
          align-items: center;
          height: 50px;
          border-bottom: 1px solid #eee;
          padding: 0 16px;
        }

        &-header {
          .material-icons {
            margin-left: auto;
            color: rgba(0, 0, 0, 0.54);
            display: none;
          }
        }

        &-row {
          &__text {
            font-size: 0.9rem;
          }
        }
      }
    }
  }

  .employment-content {
    width: calc(100% - 254px);
    display: inline-block;

    .employment-panel {
      background-color: white;
    }

    .profile-panel {
      background-color: white;
    }

    .panels {
      height: 100%;

      .search-box {
        margin-bottom: 4px;
      }

      .data-table-header {
        background-color: white;
        border-bottom: 1px solid #eee;

        .data-table__row {
          height: 50px;
        }
      }

      .data-table {
        .icon-group {
          color: rgba(0, 0, 0, 0.54);
        }
      }

      .details-table {
        &-header,
        &-row {
          display: flex;
          align-items: center;
          padding: 0 16px;
          border-bottom: 1px solid #eee;
        }

        &-header {
          height: 50px;

          .icon-group {
            display: flex;
            align-items: center;
            margin-left: auto;
            color: rgba(0, 0, 0, 0.54);

            .material-icons {
              &:not(:last-child) {
                margin-right: 12px;
              }
            }

            .edit {
              font-size: 18px;
            }
          }
        }

        &-row {
          display: flex;
          flex-wrap: wrap;

          &__sub-header {
            width: 100%;
            margin-top: 8px;
          }

          &__cell {
            display: flex;
            flex-direction: column;
            width: 25%;
            border-bottom: 1px solid #eee;
            margin-bottom: -1px;
            padding: 4px 0;
            min-height: 59px;
            justify-content: space-evenly;

            .primary-text {
              color: rgba(0, 0, 0, 0.54);
            }

            .secondary-text {
              min-height: 20px;
              margin-right: 12px;
              display: flex;
              align-items: center;
            }

            .mdc-select {
              margin-right: 12px;
              width: unset;
            }
          }
        }
      }
    }
  }
}

// People Add, Edit common styles
.form-content {
  &.basic-info {
    .file-selector-helper-text {
      margin-top: 4px;
      margin-bottom: 16px;
      display: block;
    }
  }

  &-row {
    display: flex;
    margin-bottom: 12px;
    align-items: flex-start;

    .radio-control-wrap {
      background-color: whitesmoke;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);

      .label {
        font-size: 13px;
        color: rgba(0, 0, 0, 0.6);
        position: relative;
        left: 12px;
        top: 6px;
      }

      .mdc-form-field {
        flex: 1;
      }
    }
  }

  &-container {
    .title,
    .sub-title {
      color: #0096d2;
    }

    .title {
      font-size: 0.938rem;
      font-weight: 500;
      letter-spacing: 0.04em;
      display: flex;
      align-items: center;

      .material-icons {
        margin-left: 6px;
      }
    }

    .sub-title {
      font-size: 0.875rem;
      font-weight: 400;
      letter-spacing: 0.04em;
      margin-top: 12px;
      display: flex;
      align-items: center;

      .material-icons {
        margin-left: 6px;
      }
    }
  }
}

// People Add & Edit Employment common styles
.form-content {
  &-row {
    .mdc-form-field--with-helptext {
      > div {
        width: 100%;
      }
    }

    &.align-end {
      align-items: flex-end !important;

      .work-schedule {
        position: relative;

        .mdc-select-helper-text {
          position: absolute;
          bottom: -32px;
        }
      }
    }

    > div {
      flex: 1;

      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }

  &.employment {
    width: 70% !important;

    &.employment-edit {
      width: 100% !important;

      .mdc-button {
        margin-bottom: 12px;
      }
    }

    @media screen and (max-width: 1800px) {
      width: 80% !important;
    }

    @media screen and (max-width: 1600px) {
      width: 95% !important;
    }

    .form-content-row {
      margin-bottom: unset;
    }

    .days,
    .employment-contract,
    .organisation {
      width: 66.6%;
    }

    // Employment:positions add, edit
    .position {
      flex-wrap: wrap;

      .positions {
        display: flex;
        align-items: center;
        flex: 1 0 calc(50% - 12px);
        margin-right: unset;
        margin-bottom: 12px;

        &:nth-child(odd) {
          max-width: calc(50% - 12px);
        }

        &:not(:nth-child(2n)) {
          margin-right: 12px;
        }
      }
    }

    .material-icons {
      color: grey;
    }
  }
}

// Indent
.indent-wrap {
  position: relative;
  padding-left: 12px;

  .indent-line {
    width: 2px;
    position: absolute;
    top: 8px;
    left: 0;
    bottom: 8px;
    background-color: lightgrey;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: -2px;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background-color: lightgrey;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-19 {
  margin-top: 19px;
}

.margin-bottom-12 {
  margin-bottom: 12px;
}

.margin-bottom-12--important {
  margin-bottom: 12px !important;
}

.margin-bottom-6 {
  margin-bottom: 6px;
}

.align-items-center {
  align-items: center;
}

.ActiveEmployees {
  .data-table__row--title {
    top: 92px;
  }
}
