// Organization unit
.organization-unit {
  &-people-table {
    position: relative;

    .data-table-title {
      position: unset;
    }

    .data-table__row--title {
      position: unset;
    }
  }

  .data-table-body {
    .data-table__row--two-line {
      height: 65px;

      .avatar-wrap {
        .avatar {
          width: 50px;
          height: 50px;
        }
      }

      .data-table__cell-text {
        line-height: 1.8;

        a {
          display: flex;
          align-items: center;

          .material-icons {
            color: rgba(0, 0, 0, 0.54);
            font-size: 21px;
            margin-right: 6px;
          }
        }
      }
    }
  }

  .fab-menu-container {
    height: 40px;
  }

  .organization-unit-name {
    width: 400px;
    height: inherit;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .peoples-table {
    border-top: 4px solid #eee;
    border-bottom: 4px solid #eee;
  }

  .row-spacer-header__end {
    width: 132px;
  }

  .data-table-body {
    .data-table__row {
      border-bottom: 1px solid #eee;

      .data-table__cell {
        margin-right: 12px;
      }

      &:hover {
        .data-table__cell--end {
          color: rgba(0, 0, 0, 0.54);
        }
      }

      .data-table__cell--end {
        &:hover {
          color: #0096d2;
        }
      }

      .organization-type {
        width: fit-content;
        padding: 4px;
        border-radius: 2px;
        box-sizing: border-box;
        display: block;
      }
    }
  }
}

// Temp
.indent {
  width: 4px;
  height: inherit;
  background-color: #eee;
  margin-right: 12px;
  flex-shrink: 0;

  &.indent--begin {
    &.indent--end {
      top: unset;
      bottom: unset;
      height: 38px;
    }
  }

  &.indent--begin {
    position: relative;
    top: 12px;

    &::before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: #eee;
    }

    &::before {
      position: absolute;
      top: 0;
      left: -2.5px;
    }
  }

  &.indent--end {
    position: relative;
    bottom: 6px;

    &::after {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: #eee;
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: -2.5px;
    }
  }
}
