@import '../../../../styles/_global.variables';

// Navigation
.wizard-tab-navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-bottom: 4px;
  height: 48px;
  position: sticky;
  top: 0px;
  border-top: 4px solid #eee;
  z-index: 10;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  .wizard-tab {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    &:not(:last-child) {
      &:after {
        content: '';
        display: block;
        width: 24px;
        height: 1px;
        background-color: #e8e8e8;
        margin: 0 12px;
      }
    }

    // Active
    &.active {
      .tab-leading-content {
        background-color: #0096d2;
      }

      .tab-title {
        font-weight: 500;
      }
    }

    &.success {
      .tab-leading-content {
        background-color: #3fb319;
      }
    }

    // Error
    &.error {
      .tab-leading-content {
        background-color: unset;

        .material-icons {
          font-size: 28px;
          color: #d50000;
        }
      }
    }

    .tab-leading-content {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      background-color: #bdbdbd;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      font-size: 14px;

      .material-icons {
        font-size: 15px;
      }
    }

    .tab-title {
      color: rgba(0, 0, 0, 0.87) !important;
      font-size: 14px;
    }
  }
}

// Content
.wizard-tab-content {
  background-color: white;
  position: relative;
  min-height: calc(100% - 53px);
  flex: 1;

  .add-attachments {
    padding: 12px;
    box-sizing: border-box;
  }

  .linear-progress {
    position: absolute;
    top: -5px;
  }

  .form-content {
    width: 50%;
    padding: 12px;
    box-sizing: border-box;
    padding-bottom: 60px;

    &.basic-info {
      width: 70%;

      @media screen and (max-width: 1600px) {
        width: 80% !important;
      }
    }

    &.contact-info {
      @media screen and (max-width: 1800px) {
        width: 60% !important;
      }

      @media screen and (max-width: 1600px) {
        width: 80% !important;
      }
    }

    .date-time {
      margin-left: 4px;
      display: block;
    }

    &-container {
      .title,
      .sub-title {
        color: #0096d2;
      }

      .title {
        font-size: 0.938rem;
        font-weight: 500;
        letter-spacing: 0.04em;
        display: flex;
        align-items: center;

        .material-icons {
          margin-left: 6px;
        }
      }

      .sub-title {
        font-size: 0.875rem;
        font-weight: 400;
        letter-spacing: 0.04em;
        margin-top: 12px;
        display: flex;
        align-items: center;

        .material-icons {
          margin-left: 6px;
        }
      }
    }

    &-row {
      display: flex;
      margin-bottom: 12px;

      .mdc-form-field--with-helptext {
        display: flex;
        flex-direction: column;

        &.helptext-margin-bottom {
          margin-bottom: 19px;
        }
      }
    }
  }

  &-footer {
    position: fixed;
    bottom: 0;
    background-color: white;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    width: 100%;
    padding: 12px;
    box-sizing: border-box;

    button {
      &:first-child {
        margin-right: 12px;
      }
    }
  }
}
