//
// Copyright 2018 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

@import './mixins';
@import './variables';

// postcss-bem-linter: define side-sheet

.mdc-side-sheet {
  @include mdc-side-sheet-base_;
  @include mdc-side-sheet-transitions_;
  @include mdc-side-sheet-outline-width_(1px);
  @include mdc-side-sheet-outline-color($mdc-side-sheet-outline-color);
  @include mdc-side-sheet-surface-fill-color($mdc-side-sheet-surface-fill-color);
  @include mdc-side-sheet-shape-radius(large);
  @include mdc-side-sheet-z-index($mdc-side-sheet-z-index);
  @include mdc-side-sheet-width($mdc-side-sheet-width);

  // Scrim and app content styles rely on the CSS sibling selector (~), which means the side sheet element needs to come
  // first in the source order. Unfortunately, this causes permanent side sheets to be displayed on the leading side of
  // the page instead of the trailing side. Setting `order` corrects the visual layout of the page; however,
  // screen readers and tab order are unaffected by this property, so we need to find a better long term solution.
  order: 1;
}

.mdc-side-sheet__content {
  @include mdc-side-sheet-content_;
}

.mdc-side-sheet--dismissible {
  @include mdc-side-sheet-dismissible_('mdc-side-sheet');
}

.mdc-side-sheet--modal {
  @include mdc-side-sheet-modal_('mdc-side-sheet');
  @include mdc-side-sheet-scrim-fill-color($mdc-side-sheet-modal-scrim-color);
}

.mdc-side-sheet-app-content {
  @include mdc-side-sheet-app-content_;
}

.mdc-side-sheet-scrim {
  @include mdc-side-sheet-scrim_('mdc-side-sheet');
}

// postcss-bem-linter: end
