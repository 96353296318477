// Organization units types
.organization-unit-type-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  padding: 16px;
  box-sizing: border-box;

  @media screen and (max-width: 1480px) {
    grid-template-columns: repeat(5, 1fr);
  }

  .basic-error-text {
    grid-column: 1/7;
  }

  .type-card {
    display: flex;
    flex-direction: column;
    height: 100px;
    background-color: powderblue;
    padding: 12px 12px 4px 12px;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

    &__title {
      font-size: 14px;
    }

    &__button {
      margin-top: auto;
      margin-left: auto;
    }
  }
}
