.drawer-header {
  background-color: #0096d2;
  height: 40px;
  font-size: 16px;
  padding-left: 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  color: white;
  font-weight: 500;
}

.form-content {
  &.attachment-upload {
    .file-selector-helper-text {
      margin-top: 4px;
      margin-bottom: 12px;
    }
  }
}

.side-sheet--custom-width {
  &.employment {
    width: 1100px !important;
  }

  .form-content {
    padding: 12px;
    box-sizing: border-box;
  }
}
