.MasterDataAddOrEditSheet {
  width: 400px;

  .mdc-list {
    &--two-line {
      .mdc-list-item {
        height: 72px;
        padding: 0;

        &::before,
        &::after {
          display: unset;
        }
      }
    }
  }
}

.people-list {
  .row-spacer-header__end {
    width: 44px;
  }
}

// Temp
.activity-tags,
.job-roles {
  .search-box {
    height: 50px;
    border-top: 4px solid #f1f1f1;
    border-bottom: 4px solid #f1f1f1;
  }
}

.activity-tags {
  .wireframe-design {
    .wireframe-item {
      background-color: #eee;
      width: 140px;
      height: 20px;
      border-radius: 4px;
    }
  }
}

.master-data {
  .data-table {
    &__row {
      &--title {
        min-height: 50px;
        height: unset;
      }
    }

    &__cell {
      &.name {
        width: 120px;
        flex-shrink: 0;
      }
    }
  }
}
