@import '_colors';

// Project display
@mixin projectDisplay($height: inherit, $codeMargin: 12) {
  // Color
  .project-color {
    display: block;
    width: 4px;
    height: $height;
    background-color: #5a5d62;
    border-radius: 4px 0 0 4px;
    flex-shrink: 0;
  }

  // Project code
  .project-code {
    display: flex;
    padding: 0px 5px;
    margin-right: 12px;
    line-height: normal;
    align-items: center;
    box-sizing: border-box;
    height: $height;
    background-color: #5a5d62;
    border-radius: 4px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.87);
    white-space: nowrap;

    &.border-radius {
      border-radius: 0 4px 4px 0;
    }
  }
}

%materialIcons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

$mdc-theme-primary: $dashboard-primary-color;
$mdc-theme-on-primary: white;

$mdc-theme-secondary: #14ebf1;
$mdc-theme-on-secondary: white;

$mdc-theme-background: #f5f5f5;

$mdc-theme-text-secondary-on-light: white;

$error-msg-color: #ff1b1b;
$mdc-theme-error: $error-msg-color;

// Dashboard font variables
$dashboard-font-family: Roboto, sans-serif;
$dashboard-font-size: 14px;
$dashboard-font-weight: normal;
$dashboard-primary-text-color: #fff;
$dashboard-secondary-text-color: rgba(0, 0, 0, 0.87);

$breadcrumb-separator-height: 10px;

// Breakpoints

// - Desktop
$break-large: 1600px;
$break-large-2: 1480px;

// - Tablet
$break-large-tablet-1280: 1280px;
$break-large-tablet-1024: 1024px;

$break-small-tablet: 960px;
$break-tablet: 840px;
$break-tablet-720: 720px;
$break-small-tablet-600: 600px;

// - Phone
$break-phone-480: 480px;
$break-phone-400: 400px;

$breakpoints: (
  // Desktop
  desktop-1600: 1600px,
  desktop-1480: 1480px,
  // Tablet
  tablet-1280: 1280px,
  tablet-1024: 1024px,
  tablet-960: 960px,
  tablet-840: 840px,
  tablet-720: 720px,
  tablet-600: 600px,
  // Phone
  phone-480: 480px,
  phone-400: 400px
);

@mixin mediaQuery($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "+"Please make sure it is defined in `$breakpoints` map.";
  }
}

// Prefabricated properties
@mixin box-sizing($sizing: border-box) {
  box-sizing: $sizing;
  -webkit-box-sizing: $sizing;
  -moz-box-sizing: $sizing;
}

@mixin backface-visibility($backface: hidden) {
  backface-visibility: $backface;
  -webkit-backface-visibility: $backface;
  -moz-backface-visibility: $backface;
}

@mixin user-select($select: none) {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

@mixin transform($transform) {
  transform: $transform;
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -o-transform: $transform;
  -ms-transform: $transform;
}

@mixin transition($transition) {
  transition: $transition;
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
}

// Box sizing
@mixin box-sizing($sizing: border-box) {
  box-sizing: $sizing;
  -webkit-box-sizing: $sizing;
  -moz-box-sizing: $sizing;
}

// Transform prefixes
@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
}

// Item counter
%counter {
  counter-reset: step;

  .counter-item {
    &::before {
      counter-increment: step;
      content: counter(step);
    }
  }
}

@mixin flex-box($direction, $margin: 12) {
  display: flex;
  flex-direction: $direction;

  //TODO (@if)
  align-items: center;

  .flex-box-item {
    &:not(:last-of-type) {
      margin-right: $margin + px;
    }
  }

  .flex-box-group {
    display: inline-flex;
    flex: 1;
    width: 0;

    &--responsive {
      display: inline-flex;
      flex: 0;
    }

    &__item {
      flex: 1;
      padding-right: 24px;
      box-sizing: border-box;
    }
  }
}

@mixin textOverflow() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Extend only selectors */
%clearfix::before,
%clearfix::after {
  content: '';
  clear: both;
  display: table;
}

%verticalCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

%verticalCenterRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

%horizontalCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

%horizontalCenterRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

%menuItemHover {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: $dashboard-secondary-color;
  z-index: 5;
  visibility: hidden;
}

%circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
}

%divider {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: white;
  margin: 10px 0;
}

%mdcSimpleMenuLink {
  padding: 14px 16px;
  display: block;
  @include box-sizing;
  font-size: 15px;
}

%material-icon-position--right {
  width: 24px;
  height: 24px;
  margin-left: auto;
  margin-right: 0;
}

%material-icon-position--left {
  width: 24px;
  height: 24px;
  margin-left: 0;
  margin-right: auto;
}
