.k-colorpalette {
  margin-bottom: 12px;
  width: 100%;

  .k-palette {
    width: 100%;
  }

  .k-item {
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;
  }
}
