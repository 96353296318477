.multi-selector {
  margin: 0 16px;
  width: calc(25% - 17px);

  .mdc-menu-surface {
    width: 100%;
  }

  &-list-container {
    max-height: 200px;
    overflow: auto;

    .multi-selector-list {
      padding: 0;
      min-width: 200px;

      &-item {
        padding: 0 1px;

        &::before,
        &::after {
          display: unset;
        }

        &.highlighted {
          &::before {
            opacity: 0.04;
          }
        }
      }
    }
  }

  &-search-input {
    width: 100%;
  }

  &-trigger-wrap {
    width: 200px;
    height: 50px;
    background-color: lightgray;
  }
}

.secure-group-chips {
  padding-left: 12px;

  .mdc-chip {
    flex-direction: row-reverse;
    cursor: unset;

    .rmwc-icon {
      margin-right: 4px;
      margin-left: -4px;
    }
  }
}
