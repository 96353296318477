@import '../../../styles/_global.variables';

.mdc-dialog__header__title,
.mdc-typography--headline,
.mdc-typography--subheading1,
.mdc-typography--title {
  color: #0096d2;
}

.MonthlyClose {
  .mdc-button {
    &.disabled {
      color: rgba(0, 0, 0, 0.37);
      cursor: default;

      &.mdc-button--unelevated {
        background-color: rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.37);
      }

      &:hover,
      &:active {
        &::before,
        &::after {
          opacity: 0;
          background-color: unset;
        }
      }

      .rmwc-icon {
        cursor: default;
      }
    }
  }

  .data-table {
    padding-bottom: 50px;
  }

  .Collapsible {
    background: #f1f1f1;
    flex: 1;

    .mdc-switch {
      &__knob {
        display: none;
      }
    }

    .data-table {
      padding-bottom: 50px;

      &__cell {
        &:not(.person) {
          &:not(.cost) {
            &:not(.basic-hours) {
              min-width: max-content;
            }
          }
        }

        &.person {
          width: 200px;
          flex-shrink: 0;
        }

        &.cost {
          .costInput {
            .mdc-text-field__input {
              text-align: right;
              padding-right: 12px;
              padding-left: 12px;
            }
          }
        }
      }
    }
  }

  ::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(0, 0, 0, 0.87) !important;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: rgba(0, 0, 0, 0.87) !important;
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .sub-title {
    display: flex;
    align-items: center;

    &.sticky {
      position: sticky;
      top: 96px;
      z-index: 29;
      font-weight: 400;
    }

    &.sticky-2 {
      position: sticky;
      top: 112px;
      z-index: 29;
    }

    .button-group {
      display: flex;
      align-items: center;
      margin-left: auto;
      grid-gap: 12px;

      .file-selector-wrap {
        .file-selector__label {
          height: 32px;
          font-size: 0.8125rem;
        }
        .file-selector__input {
          display: none;
        }
      }
    }
  }

  .tags {
    display: flex;

    .tag {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 50%;
      background-color: #5a5d62;
      border-radius: 4px;
      padding: 3px 5px;
      color: rgba(255, 255, 255, 0.87);
      font-size: 11px;

      &.full-width {
        max-width: inherit;
      }

      &:not(:last-of-type) {
        margin-right: 4px;
      }
    }
  }

  .monthlyclose-circular-progress {
    /* 
     40+46+72
     40 => breadcrumb header height
     46 => worktime restriction section height
     72 => monthly scroll section height
    */
    //TODO ZSOLTI - I think these solutions is too hacky and not scaleable :)
    top: 168px !important;
  }

  .panel-content-wrapper--without-button-group {
    overflow: unset;
    display: flex;
    flex-direction: column;
  }

  .check-table {
    height: 100%;

    .more-tag {
      cursor: pointer;
    }

    .sub-title {
      display: flex;
      align-items: center;

      .material-icons {
        margin-left: auto;
        color: rgba(0, 0, 0, 0.54);
        padding: 4px;
        box-sizing: border-box;
        font-size: 18px;
        border-radius: 50%;

        &:hover {
          background-color: rgba(0, 0, 0, 0.12);
        }
      }
    }

    .data-table__cell {
      &.not-closed-activity,
      &.pending-leave-request,
      &.pending-overtime-request,
      &.days-without-status,
      &.activity-without-project,
      &.activity-more-than-eight-working-hours,
      &.overlapped-activity {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        .material-icons {
          transform: rotate(0);
          transition: transform 0.3s ease;
        }
      }

      &.warning,
      &.error {
        cursor: pointer;

        span {
          font-weight: bold;
        }
      }

      &.disabled {
        color: rgba(128, 128, 128, 0.64);
      }

      &.warning {
        color: $dashboard-secondary-color;
      }

      &.error {
        color: $error-msg-color;
      }

      &.comment {
        min-width: 100px !important;
        max-width: 400px;
      }

      &.project {
        min-width: 200px !important;
        width: 100%;
        flex-shrink: 0;
      }
    }

    .data-table {
      .data-table__row {
        padding: 0px 16px 0px 16px;

        &--title {
          height: 80px;
          top: 146px;

          .data-table__cell {
            flex-direction: column;
            justify-content: space-around;
            padding-top: 6px;

            &:hover {
              background-color: unset !important;
            }

            &.person {
              justify-content: flex-start !important;
            }
          }
        }

        .data-table__cell {
          border-right: 1px solid #eee;
          height: inherit;
          padding-left: 4px;
          padding-right: 4px;
          box-sizing: border-box;
          margin-right: unset;

          .mdc-switch {
            margin-top: auto;
            margin-bottom: 12px;
          }

          &.person {
            width: 250px;
            flex-shrink: 0;
          }

          &.days-without-status {
            cursor: default;
          }

          &:not(.disabled) {
            &:not(.person) {
              &:not(.days-without-status) {
                &:hover {
                  background-color: #f1f1f1;
                }
              }
            }

            &.active {
              background-color: #f1f1f1;

              .material-icons {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }

    > .data-table {
      .data-table__row {
        &:first-child {
          border-top: 0px solid #eee;
        }
      }
    }

    .empty-cell {
      color: rgba(128, 128, 128, 0.64);
    }

    // Project wrap
    .project-selector-wrap {
      display: flex;
      flex-direction: column;
      max-width: 350px;

      .project-selector {
        display: flex;
        align-items: center;
        padding-left: unset;

        &:not(:last-of-type) {
          margin-bottom: 8px;
        }

        @include projectDisplay(20px);
      }
    }

    .person-selector {
      width: 200px;
    }

    .date {
      width: 150px;
    }

    .tag-selector {
      width: 440px;
      max-width: unset !important;
    }

    .time-begin {
      width: 80px;
    }

    .data-table__cell--end {
      color: $dashboard-primary-color;
    }

    .data-table__row {
      border-bottom: 1px solid #eee;
    }
  }

  .data-table {
    &__cell {
      &.person {
        width: 250px;
        flex-shrink: 0;
      }
    }

    .InnerTable {
      border-top: 4px solid #f1f1f1;
      border-bottom: 4px solid #f1f1f1;

      .sub-title {
        border: unset;
        cursor: default;
        padding-left: 0;
      }

      .data-table__row {
        padding: 8px 16px 8px 16px;

        .time-end {
          width: 80px;
          margin-right: 24px !important;
        }

        .data-table__cell {
          border-right: unset !important;
          margin-right: 12px;
          padding-left: 0 !important;

          &:hover {
            background-color: unset !important;
          }
        }
      }

      .data-table-header {
        .data-table__row {
          padding: 8px 16px;

          .sub-title {
            padding: unset;
          }
        }
      }
    }

    &.ActivityList {
      padding-bottom: unset;

      .data-table__row {
        height: auto;

        &--title {
          border-top: unset;
          top: 0;
          z-index: unset;
        }
      }
    }
  }

  .mdc-text-field {
    background-color: unset !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    height: 56px !important;

    &--disabled {
      ::-webkit-input-placeholder {
        color: rgba(104, 104, 104, 0.87) !important;
      }

      ::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(104, 104, 104, 0.87) !important;
      }

      :-moz-placeholder {
        /* Firefox 18- */
        color: rgba(104, 104, 104, 0.87) !important;
      }

      :-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(104, 104, 104, 0.87) !important;
      }
    }
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    display: block;
    width: 100%;
  }

  .worktime-restriction {
    background-color: white;
    padding: 0 16px;
    color: #0096d2;
    height: 56px;
    display: flex;
    align-items: center;

    .label-wrap {
      display: flex;
      align-items: center;

      .material-icons {
        margin-left: 6px;
        color: rgba(0, 0, 0, 0.47);
        font-size: 20px;
      }
    }

    .datepicker-input {
      margin: 0 12px;
    }

    .button-group {
      .mdc-button {
        &:last-child {
          margin-left: 12px;
        }
      }
    }
  }

  .calendar-pager {
    position: sticky;
    top: 40px;
    margin: 0;
    z-index: 29;
    border-top: 4px solid #eee;
    border-bottom: 4px solid #f1f1f1;
  }

  .data-table__row {
    padding: 8px 16px 8px 16px;
    height: 56px;

    &--title {
      position: sticky;
      top: 158px;
      background-color: white;
      z-index: 9;
      border-top: 4px solid #f1f1f1;
    }
  }

  .main-title {
    background-color: white;
    padding: 8px 16px;
    color: #0096d2;
    width: 100%;
    justify-content: center;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    position: sticky;
    top: 0;
    z-index: 100;
    font-size: 16px;
    line-height: 1.25rem;
    text-align: center;
    font-weight: 500;
    letter-spacing: 0.04em;
    margin: 0;
  }

  .sub-title {
    margin: 0;
    background-color: white;
    padding: 8px 16px;
    position: relative;
    height: 50px;
  }

  .description {
    position: relative;
    border-bottom: 1px solid #eee;
    padding: 16px 8px 8px 16px;
    margin: 0;
    background-color: white;
    color: #0096d2;
    display: flex;
    flex-direction: column;

    .text {
      font-size: 0.875rem;
      letter-spacing: 0.04em;
      line-height: 1.3rem;
    }

    .button-group {
      align-self: flex-end;
    }

    .monthly-close_button {
      margin-left: 16px;
    }
  }

  .name-list {
    background-color: white;
    padding-bottom: 0;
    padding-top: 0;
  }

  .footer {
    // height: 54px;
    background-color: white;
    padding: 8px 16px;
    box-sizing: border-box;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #eee;
    position: fixed;
    bottom: 0;
    width: calc(100% - 355px);

    @media screen and (max-width: 1440px) {
      width: calc(100% - 100px);
    }

    .button-group {
      margin: unset;

      .mdc-button {
        &:not(:last-child) {
          margin-right: 12px;
        }
      }
    }

    .number {
      margin: 0 4px;
    }
  }
}

.worktime-restriction__tooltip {
  width: 20rem;
}
