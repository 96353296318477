﻿// Dashboard color variables
$dashboard-primary-color: #0096d2;
$dashboard-primary-light-color: #00a0e6;
$dashboard-secondary-color: rgba(255, 140, 0, 1);
$dashboard-tertiary-color: #f1f1f1;

// Material color variables
$mdc-theme-primary: $dashboard-primary-color;
$mdc-theme-primary-ripple-bg: rgba($dashboard-primary-color, 0.14);
$mdc-button-primary: $dashboard-primary-color;

$mdc-theme-secondary: $dashboard-primary-color;

:root {
  --mdc-theme-secondary: #0096d2;
}
