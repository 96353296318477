.ApplicationUsers {
  .search-box {
    height: 50px;
    border-top: 4px solid #f1f1f1;
    border-bottom: 4px solid #f1f1f1;
  }

  .wireframe-design {
    .checkbox {
      .wireframe-item {
        width: 20px;
      }
    }

    .wireframe-item {
      background-color: #eee;
      width: 140px;
      height: 20px;
      border-radius: 4px;
    }
  }
}

.users-list {
  .data-table {
    &__cell {
      &.active {
        width: 100px;
      }
    }
  }
}
