@import '_reset';
@import '_global.variables';
@import '_dashboard.layout';
@import '_display';

@import '../components/SideSheet/index.scss';

@import '@material/typography/mdc-typography';
@import '@material/textfield/mdc-text-field';
@import '@material/button/mdc-button';
@import '@material/select/mdc-select';
@import '@material/snackbar/mdc-snackbar';
@import '@material/icon-button/mdc-icon-button';
@import '@material/menu/mdc-menu';
@import '@material/menu-surface/mdc-menu-surface';
@import '@material/list/mdc-list';
@import '@material/dialog/mdc-dialog';
@import '@material/snackbar/mdc-snackbar';
@import '@material/checkbox/mdc-checkbox';
@import '@material/tab/mdc-tab';
@import '@material/tab-bar/mdc-tab-bar';
@import '@material/tab-scroller/mdc-tab-scroller';
@import '@material/tab-indicator/mdc-tab-indicator';
@import '@material/drawer/mdc-drawer';
@import '@material/radio/mdc-radio';
@import '@material/form-field/mdc-form-field';
@import '@material/fab/mdc-fab';
@import '@material/ripple/mdc-ripple';
@import '@material/switch/mdc-switch';
@import '@material/chips/mdc-chips';
@import '@material/linear-progress/mdc-linear-progress';
@import '@rmwc/data-table/data-table.css';

/* Html */
html {
  font: {
    family: $dashboard-font-family;
    weight: $dashboard-font-weight;
  }
}

html {
  height: 100%;
  overflow: auto;
}

body {
  height: 100%;
}

html,
body {
  color: rgba(0, 0, 0, 0.87);
  background-color: #f1f1f1;

  @include mediaQuery(tablet-840) {
    background-color: #f1f1f1;
    height: unset;
  }
}

input[type='date']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

textarea {
  resize: none !important;
  & ~ .mdc-floating-label {
    padding-left: 0.8rem;
  }
}

/* Breadcrumbs */
.breadcrumb-wrapper {
  position: absolute;
  top: 50%;
  left: $dashboard-menu-width + $dashboard-secondary-panel-width + $dashboard-padding * 3;
  transform: translateY(-50%);
  @extend %clearfix;

  @media screen and (max-width: 1440px) {
    left: 110px;
  }

  @include mediaQuery(tablet-840) {
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .breadcrumb {
    counter-reset: number;
    height: 100%;

    &-item {
      float: left;
      position: relative;
      height: 100%;
      padding-right: 10px;
      @extend %verticalCenterRow;

      @include mediaQuery(tablet-840) {
        display: none;

        &:last-child {
          display: block;
          white-space: nowrap;
          padding-right: 0;

          span {
            font-size: 15px;
          }
        }
      }

      a {
        @extend %verticalCenterRow;
        height: 100%;
        text-decoration: underline;
      }

      span {
        color: white;
      }

      &:not(:last-of-type)::after {
        content: '';
        position: relative;
        left: 0px;
        width: $breadcrumb-separator-height;
        height: $breadcrumb-separator-height;
        @include transform(scale(0.707) rotate(45deg));
        z-index: 1;
        border-radius: 0 5px 0 50px;
        @include transition(background 0.5s);
        box-shadow: 2px -2px 0 0 $dashboard-tertiary-color;
        display: inline-block;
      }
    }
  }
}

.error-outlined {
  color: #a90202 !important;
  border-color: #a90202 !important;

  &.mdc-button::before {
    background-color: #a90202;
  }
}

.color-blue {
  color: $dashboard-primary-color;
}

.mdc-tab-bar {
  background-color: white;
  position: sticky;
  z-index: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mdc-switch {
  &__knob {
    display: none;
  }
}

.drawer-body {
  padding: 12px;
  box-sizing: border-box;

  .mdc-form-fields {
    display: flex;
    align-items: center;

    .mdc-switch {
      margin-right: 12px;
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    .field {
      flex: 1;

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}

.mdc-drawer--right {
  z-index: unset !important;

  .mdc-drawer__drawer {
    padding: 0;

    @media (min-width: 600px) {
      width: $dashboard-right-side-bar;
      max-width: $dashboard-right-side-bar;
    }

    .mdc-button {
      margin-left: 16px;
    }
  }
}

.tags-surface {
  .header {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 8px;
    box-sizing: border-box;
    border-bottom: 1px solid #eee;

    .title {
      font-size: 13px;
      padding: 4px;
    }

    i {
      margin-left: auto;
      font-size: 18px;
      padding: 4px;
      border-radius: 50%;
      outline: none;

      &:hover {
        background-color: rgba(60, 64, 68, 0.24);
      }
    }
  }

  .tags {
    padding: 8px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    cursor: pointer;

    .tag {
      background-color: #5a5d62;
      border-radius: 4px;
      padding: 3px 5px;
      font-size: 13px;
      color: rgba(255, 255, 255, 0.87);
      width: fit-content;

      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }
  }
}

// Project wrap
.project-selector-wrap {
  display: flex;
  flex-direction: column;

  .project-selector {
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }

    @include projectDisplay(20px);
  }
}

.mdc-list-group--header {
  padding: 8px 16px;
  border-bottom: 1px solid #eee;
}

.mdc-list-group {
  padding: 0 !important;

  &--edit {
    .mdc-list-item__text {
      &:not(:last-child) {
        margin-right: 4px;
      }
    }
  }

  .mdc-typography--subheading1 {
    padding-left: 16px;
  }

  .header-with--icon_wrap {
    margin-left: auto;
    height: 24px;
    color: rgba(0, 0, 0, 0.54);

    .material-icons {
      border-radius: 50%;

      &:hover {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
  }

  .header-with-icon {
    .material-icons {
      color: rgba(0, 0, 0, 0.54) !important;
    }
  }

  .mdc-list {
    &-item {
      padding: 0 16px;
    }

    &--two-line {
      .mdc-list-item__text {
        display: inline-flex;
        flex-direction: column; //TODO in new release list-item__text and secondary-text has been changed to block
      }
    }
  }

  &__subheader {
    margin: 0;
    padding: 8px 16px;
    @include box-sizing;
    border-bottom: 1px solid #eee;

    &.header-with--icon {
      display: flex;
      align-items: center;

      .edit-icon {
        font-size: 18px;
        padding: 4px;
      }
    }

    &--additional-title {
      display: flex;

      &__text {
        margin-left: auto;
        margin-right: 0;

        &--with-icon {
          .material-icons {
            margin-left: 10px;
          }
        }
      }
    }
  }

  &--subheader-left {
    text-align: left;
  }

  .mdc-list-divider {
    border-bottom: 1px solid #eee;
  }
}

.mdc-list {
  width: 100%;
  position: relative;

  .mdc-list-item__secondary-text {
    font-size: 0.775rem;
  }

  .mdc-typography--body1 {
    font-size: 0.85rem;
  }

  .mdc-list-item {
    margin: 0;
    padding: 0 16px;
    @include box-sizing;
    height: auto;
    min-height: 50px;
    border-bottom: 1px solid #eee;
  }
}

#errorSnackbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  transform: translateY(-100%);
}

.mdc-snackbar {
  position: relative;
  background-color: unset;
  transform: translate(0%, 100%);
  z-index: 9999999;

  .mdc-snackbar__surface {
    min-width: unset;
    max-width: unset;
  }

  &.error {
    .mdc-snackbar__surface {
      background-color: #a90202;
    }
  }

  &.warning {
    .mdc-snackbar__surface {
      background-color: #feefb3;

      .mdc-snackbar__label {
        color: #9f6000;
      }
    }
  }

  &.success {
    .mdc-snackbar__surface {
      background-color: #6e9c21;
    }
  }
}

.mdc-select {
  background-image: unset;

  &:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
    color: #0096d2;
  }

  &__dropdown-icon {
    fill: #000;
    opacity: 0.54;
  }
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #0096d2;
}

.mdc-typography {
  &--body1 {
    font-size: 13px;
  }
}

.material-icons {
  cursor: pointer;
  outline: none;
}

.calendar {
  &-pager {
    background-color: white;
    padding: 8px 16px;
    color: rgba(0, 150, 210, 1);
    width: 100%;
    margin-bottom: 4px;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .prev-date,
    .next-date {
      height: 32px;

      .material-icons {
        font-size: 32px;
        outline: none;
      }
    }

    .date {
      font-size: 18px;
      height: auto;
      margin: 0 24px;
      width: 200px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// Temp layou
.temp-layout {
  height: inherit;

  &-header {
    height: 40px;
    background-color: #0096d2;
  }

  &-body {
    min-height: calc(100% - 40px);
    height: calc(100% - 40px);
    display: flex;

    &-left-side {
      width: 350px;
      height: 100%;
      display: flex;

      .main-menu {
        display: block;
        width: 100px;
        height: 100%;
        background-color: #00a0e6;
      }

      .sub-menu {
        width: 250px;
        background-color: white;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
      }
    }

    &-right-side {
      flex: 1;
      padding: 4px;
      box-sizing: border-box;
    }
  }
}

// Global variables

// Flexbox
@mixin flex-box($direction, $margin: 12) {
  display: flex;
  flex-direction: $direction;

  //TODO (@if)
  align-items: center;

  .flex-box-item {
    &:not(:last-of-type) {
      margin-right: $margin + px;
    }
  }

  .flex-box-group {
    display: inline-flex;
    flex: 1;

    &__item {
      flex: 1;
      box-sizing: border-box;
      margin-right: $margin + px;
    }

    &--column {
      flex-direction: column;
    }

    &--responsive {
      &__1480 {
        @media screen and (max-width: 1480px) {
          flex-direction: column;
        }
      }
    }
  }
}

// Indent temp
.indent-wrap {
  .indent {
    position: relative;
    left: 0px;
    width: 2px;
    height: 20px;
    background-color: #5a5d62;
    margin-right: 12px;
    flex-shrink: 0;

    &.indent--end {
      &::before,
      &::after {
        content: '';
        width: 5px;
        height: 5px;
        background-color: #5a5d62;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      &::after {
        bottom: 0;
        top: unset;
      }
    }
  }
}

// Avatar
.avatar-wrap {
  display: flex;
  align-items: center;
  position: relative;

  &.big-avatar {
    .avatar {
      width: 100px;
      height: 100px;

      + .private-icon {
        left: 70px;
        font-size: 26px;
      }
    }
  }

  .monogram {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #5b6065;
    color: white;
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 8px;
    flex-shrink: 0;

    + .private-icon {
      position: absolute;
      bottom: -3px;
      left: 24px;
      color: #ff8c00;
      font-size: 20px;
    }

    img {
      width: 100%;
      border-radius: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

// Product Image
.product-image-wrap {
  display: flex;
  align-items: center;
  position: relative;

  .image-square {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    flex-shrink: 0;

    img {
      width: 100%;
      border-radius: 4px;
      height: 100%;
      object-fit: cover;
    }
  }

  .select-task-attachment {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    flex-shrink: 0;

    img {
      width: 100%;
      border-radius: 4px;
      height: 100%;
      object-fit: cover;
    }
  }

  .product-images {
    width: 120px;
    height: 120px;
    border-radius: 4px;
    margin-right: 8px;
    flex-shrink: 0;

    img {
      width: 100%;
      border-radius: 4px;
      height: 100%;
      object-fit: cover;
    }
  }
}

.mdc-button {
  .circular-progress-wrap {
    display: unset;
    z-index: unset;
    padding: unset;
    border-radius: unset;

    .mdc-circular-progress {
      width: unset;
      height: unset;
    }
  }
}

.form-field {
  position: relative;

  .circular-progress-wrap {
    position: absolute;
    width: 100%;
    height: 56px;
    box-sizing: border-box;
  }
}

.mdc-menu-surface--open {
  overflow: hidden;
  width: max-content;

  .mdc-list {
    overflow: hidden;
  }
}

// Bottom bar
.bottom-bar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 24px;
  width: fit-content;
  margin: 0 auto;
  background-color: #2c343e;
  padding: 8px 12px;
  border-radius: 4px;
  z-index: 9999999;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.87);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: transform 0.3s ease-out;
  transform: translateY(calc(100% + 32px));

  .text {
    font-size: 14px;
    margin-right: 12px;
  }

  &.hide {
    transform: translateY(calc(100% + 32px));
  }

  &.open {
    transform: translateY(0);
  }

  .item-counter {
    font-size: 13px;
    letter-spacing: 0.58px;
    margin-right: 16px;

    .number {
      color: #14ebf1;
      font-size: 14px;
    }
  }

  .action-group {
    display: flex;
    align-items: center;
    border-left: 1px solid #3c4044;
    padding-left: 8px;

    .mdc-button {
      font-size: 13px;
    }

    .material-icons {
      cursor: pointer;

      &.delete {
        color: #b52222;
        margin-right: 8px;
      }
    }
  }
}

.mdc-dialog {
  z-index: 9999999;
}

// Data table
.rmwc-data-table {
  letter-spacing: 0.85px;
  border-left: 0px;
  border-top: 0px;
  border-color: #eee;
  width: 100%;

  &__body {
    font-size: 13px;
  }

  &__head {
    letter-spacing: 03333em;
    line-height: 1.25rem;
    font-size: 0.75rem;
    font-weight: 400;
    font-family: $dashboard-font-family;
    -webkit-font-smoothing: antialiased;
    text-transform: uppercase;
  }

  &__cell {
    border-bottom-color: #eee;
    .indent-wrap {
      padding: 0;
    }

    &.active {
      color: #0096d2;
    }

    .with-copy {
      display: flex;
      width: 100%;
      height: -webkit-fill-available;
      gap: 0.5rem;
      align-items: center;

      i {
        font-size: medium;
        color: #eee;

        &:hover {
          cursor: pointer;
          color: #0096d2;
        }
      }
    }
  }

  .sorts {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    vertical-align: text-bottom;
    margin-left: 0.5rem;
  }

  .filter-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-left: 0.5rem;

    i {
      vertical-align: text-bottom;
    }

    .mdc-checkbox {
      top: 1px;
    }
  }

  .filter {
    color: rgba(0, 0, 0, 0.38);
    font-size: 1rem;
    font-weight: 800;

    &:hover {
      color: rgba(0, 0, 0, 0.54);
    }

    &.active {
      color: #0096d2;
    }
  }

  .arrow {
    color: rgba(0, 0, 0, 0.54);
  }

  .more-icons {
    & > i:first-of-type {
      margin-right: 1rem;
    }
  }

  .add {
    color: #00a0e6;
    vertical-align: middle;
    margin-right: 1rem;
  }

  .pdf {
    cursor: default;
    width: 40px;
    height: 40px;
    font-size: 40px;
    color: #757575;
  }

  .checkbox {
    margin: -1rem -1rem -1rem -11px;
  }

  &__row:hover {
    .arrow {
      color: #00a0e6;
    }
  }

  &__search {
    width: 100%;
    margin-top: 2rem;
  }
}

.data-table {
  background-color: white;

  &-end {
    justify-content: center;
    align-items: center;
  }

  &-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding: 0 16px;
    margin: 0;
    height: 50px;
    background-color: white;
    position: sticky;
    background-color: white;
    z-index: 8;
    border-top: 4px solid #eee;

    .title {
      color: #0096d2;
      font-weight: 500;
      display: flex;
      align-items: center;
    }

    &__inner-wrap {
      display: flex;
      align-items: center;

      .img {
        margin-right: 12px;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        overflow: hidden;
      }
    }
  }
  &-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    background: #fff;
    z-index: 1;

    button {
      all: unset;
      display: flex;
      gap: 1rem;
      align-items: center;
      padding: 1rem;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.54);
      font-family: $dashboard-font-family;
      font-size: 13px;
      text-transform: uppercase;

      &:disabled,
      &:disabled i {
        cursor: default;
        color: rgba(0, 0, 0, 0.32);

        &:hover {
          background-color: inherit;
        }
      }

      i {
        color: rgba(0, 0, 0, 0.38);
      }

      &:hover {
        background-color: #eee;
      }
    }
  }

  .not-found-data {
    width: 100%;
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
    justify-content: center;

    &.align-left {
      justify-content: flex-start;
    }

    &.error {
      color: #d50000;
    }
  }

  &__row {
    @include flex-box(row);
    padding: 8px 16px 8px 16px;
    box-sizing: border-box;
    position: relative;
    height: 50px;
    border-bottom: 1px solid #eee;

    &--two-line {
    }

    &:not(.data-table__row--title) {
      &:not(.data-table__row--header) {
        &:hover {
          background-color: #f4f4f4 !important;

          .data-table__cell--end {
            color: #00a0e6;
          }
        }
      }
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid #eee;
    }

    &--inline-edit {
      @include flex-box(row);

      &:hover {
        background-color: unset !important;
      }
    }

    &--title {
      border-bottom: 1px solid #eee;
      padding: 8px 16px 8px 16px;
      margin: 0;
      height: 50px;
      box-sizing: border-box;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.54);
      position: sticky;
      background-color: white;
      z-index: 7;

      .data-table__cell--end {
        color: inherit;
      }

      .data-table__cell--two-line {
        line-height: 18px;
      }

      .data-table__cell--graphic {
        width: 40px;
        flex-shrink: 0;
      }

      .row-spacer-header__end {
        width: 44px;
        flex-shrink: 0;

        &--three-item {
          width: 132px;
        }
      }
    }
  }

  &__cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 13px;
    font-weight: normal;
    letter-spacing: 0.85px;
    width: 50%;
    max-width: 50%;

    &.align-center {
      text-align: center;
      align-items: center;
    }

    &--graphic {
      margin-right: 8px;
      width: 40px;
      flex-shrink: 0;
    }

    &--end {
      margin-left: auto;
      font-size: 13px;
      font-weight: normal;
      letter-spacing: 0.85px;
      color: rgba(0, 0, 0, 0.54);
      width: 44px;
      flex-shrink: 0;
      justify-content: flex-end;
      display: flex;
    }

    &--meta {
      cursor: pointer;
    }

    &--align-right {
      text-align: right;
    }

    &--align-center {
      text-align: center;
    }
  }

  &-body {
    .data-table__row {
      &:hover {
        background-color: white;
      }
    }
  }
}

$animation-fab-menu-icon-width: 25px;
$animation-fab-menu-icon-span-wieght: 3px;

$counter: 3;

@for $i from 1 through $counter {
  $transition-duration: 0.15s;
  $transition: opacity #{$transition-duration} ease, transform #{$transition-duration} ease #{$i}00ms;

  .fab-menu-container__view {
    .mdc-fab-#{$i} {
      @include transition($transition);
    }
  }
}

.fab-menu-container--bottom-right {
  position: relative;

  .fab-menu-container {
    bottom: 8px;
    right: 8px;
  }
}

.fab-menu-container--top-position {
  &.fab-menu-container {
    top: 65px !important;
    z-index: 999;
  }
}

.fab-menu-container--top-right {
  position: relative;

  &.fab-menu-container--inline-block {
    position: relative;
    display: inline-block;

    @media screen and (max-width: $break-small-tablet-600) {
      padding-right: 0;
      width: 100%;
    }

    &.fab-menu-container--button-position {
      .fab-menu-container {
        right: 0;
      }
    }

    .fab-menu-container {
      right: 0;
    }
  }

  .fab-menu-container {
    top: $dashboard-header-height;
    right: 20px;

    @media screen and (max-width: $break-tablet) {
      top: #{$dashboard-header-height + 15px};
    }

    .mdc-list-item {
      min-height: auto;
      height: 49px;
      width: 100%;
      padding: 0;

      .mdc-menu--link {
        padding: 13px 16px !important;
      }
    }

    @media screen and (max-width: $break-small-tablet-600) {
      position: fixed;
      top: auto !important;
      bottom: 0.5rem;
    }

    &--mini {
      top: 10px;
      right: 10px;

      &-fix {
        position: fixed;
        top: 97px;
        right: 30px;
      }
    }
  }
}

.mdc-fab--mini {
  background-color: darkorange;
}

.fab-menu-container {
  position: fixed;
  z-index: 10;
  right: 40px;
  top: 40px;
  height: 100px;

  .mdc-list {
    position: absolute;
    height: 100px;

    .mdc-menu--link {
      min-height: inherit;
      display: flex;
      align-items: center;
    }
  }

  &__view {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    transition: transform 375ms cubic-bezier(0, 0, 0.2, 1);
    height: auto;
    width: 100%;
    opacity: 1;
    transform: translateY(-100%);
    will-change: transform;
    text-align: center;

    .mdc-fab {
      opacity: 1;
      @include transform(scale(1));
    }

    &--exited {
      transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
      transition: opacity 0.5s ease 600ms;

      .mdc-fab {
        opacity: 0;
      }
    }
  }

  .mdc-fab {
    margin: 0.5rem;

    .animation-fab-menu__icon {
      width: $animation-fab-menu-icon-width;
      @extend %verticalCenter;

      span {
        width: 100%;
        height: $animation-fab-menu-icon-span-wieght;
        background-color: white;
        display: block;
        @include transition(transform 0.5s ease);

        &:nth-child(2) {
          @include transition(opacity 0.5s ease 100ms);
        }

        &:not(:last-of-type) {
          margin-bottom: 4px;
        }
      }
    }

    &--exited {
      opacity: 1;
      @include transform(scale(1));

      .animation-fab-menu__icon {
        span {
          @include transition(transform 0.5s ease);
          @include backface-visibility;

          &:first-of-type {
            $menu-transform-first-span: rotate(45deg) translate(5px, 5px);
            @include transform($menu-transform-first-span);
          }

          &:nth-child(2) {
            opacity: 0;
            @include transition(opacity 0.5s ease);
          }

          &:last-of-type {
            $menu-transform-last-span: rotate(-45deg) translate(5px, -5px);
            @include transform($menu-transform-last-span);
          }
        }
      }
    }
  }
}

.file-selector {
  position: relative;
  width: 100%;
  background-color: whitesmoke;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);

  .file-upload-progress {
    margin-right: unset;
  }

  .error-box {
    padding: unset;
    margin-left: auto;

    .error-text {
      font-size: 12px;
      letter-spacing: 0.01rem;
      color: #d50000;
    }
  }

  + .file-selector-helper-text {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    margin-left: 12px;
  }

  &-drag-zone {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
  }

  &_title {
    color: rgba(0, 0, 0, 0.6);
    font-size: 13px;
    position: absolute;
    top: 12px;
    left: 12px;
  }

  &_input {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  &_input-compact {
    position: absolute;
    top: 0;
    width: 80%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 999;
  }

  &_label {
    color: rgba(0, 0, 0, 0.6);
  }
}

.file-uploaded-list {
  .file-uploaded-list-item {
    display: flex;
    align-items: center;
    padding: 0 12px;
    padding-bottom: 12px;
    position: relative;

    .left-side-wrap {
      width: 40px;
    }

    &:first-child {
      padding-top: 12px;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }

    &:not(:last-child) {
      margin-bottom: 12px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    .file-extension-wrapper {
      position: relative;
      width: 24px;
      height: 34px;
      border: 2px solid rgba(0, 0, 0, 0.32);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: -2px;
        right: -2px;
        background-color: grey;
        width: 10px;
        height: 10px;
      }

      .file-extension {
        font-size: 11px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  .file-data {
    position: relative;
    padding: 8px;
    flex: 1;
  }

  .file-name {
    max-width: 100%;
    white-space: normal;
    font-size: 14px;
    margin-bottom: 4px;
    word-wrap: break-word;
    word-break: break-all;
  }

  .file-size {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.6);
  }

  .file-remove {
    color: rgba(0, 0, 0, 0.6);
    font-size: 20px;
  }
}

// Text overflow
.text-overflow {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// LinearProgress position
.linear-progress {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;

  &.full-top {
    top: -4px;
  }
}

// CircularProgress scrim
.loader-scrim {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.mdc-side-sheet {
  border: unset;

  &-app-content {
    flex: 1;
    overflow-x: hidden;
    height: unset !important;
  }
}

.mdc-side-sheet > div {
  overflow-y: unset !important;
  overflow-x: unset !important;
}

.mdc-side-sheet .form-content {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  padding: 12px;
  box-sizing: border-box;

  .file-selector-helper-text,
  .file-selector + .file-selector-helper-text {
    margin: 12px 0;
  }
}

.error-box {
  padding: 0 16px;

  .basic-error-text {
    justify-content: space-between;
  }
}

.basic-error {
  &-text,
  &-table {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-text {
    color: #d50000;
    border-bottom: 1px solid #eee;
  }

  &-table {
    color: rgba(0, 0, 0, 0.32);
  }

  &-cell {
    padding: 1.5rem 2rem;
    margin-inline: auto;
    text-align: center;
    color: rgba(0, 0, 0, 0.32);
  }
}

.mdc-select-helper-text {
  &--validation-msg {
    color: #ff1b1b;
  }
}

.mdc-sheet-fab--container {
  position: relative;
  top: 0;
  width: 200px;
  overflow: hidden;
  min-height: 0;
  max-height: 400px;
  height: calc(100%);
  display: inline-block;
  transition: box-shadow 250ms ease-in, top 0.1ms ease-in 250ms;

  .mdc-sheet-fab {
    .mdc-fab {
      display: block;
      float: right;
      top: 0;
      right: 0;
      transform: scale(1);
      transition: transform 400ms ease-out, background-color 150ms ease-out 300ms, right 450ms ease-out 250ms, top 400ms ease 200ms, box-shadow 300ms ease 250ms;

      i {
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(0);
        opacity: 1;
        transition: opacity 400ms ease 300ms, transform 400ms ease-out 150ms;
      }
    }

    .mdc-list {
      pointer-events: none;
      opacity: 0;
      transition: opacity 150ms ease 150ms;
      padding: 0;
      overflow: unset;

      .mdc-list-item {
        display: flex;

        &[aria-disabled='true'] {
          .mdc-menu--link {
            cursor: default;
            color: rgba(0, 0, 0, 0.38);
          }
        }

        .mdc-menu--link {
          color: rgba(0, 0, 0, 0.87);
          position: relative;
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }

  &-animate {
    top: 4px;
    overflow: hidden;
    transform: translateY(0);
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    transition: box-shadow 200ms ease-in 250ms, top 0.1ms ease-in 250ms;

    .mdc-sheet-fab {
      .mdc-fab {
        top: 20px;
        right: 30%;
        display: block;
        transform: scale(12);
        background-color: white;
        box-shadow: none;
        transition: right 450ms ease-out, top 400ms ease-in 150ms, transform 400ms ease-in 150ms, background-color 150ms ease-in 150ms;

        i {
          opacity: 0;
          transform: translate(-50%, -50%) rotate(45deg);
          transform-origin: center;
          transition: transform 250ms ease 50ms, opacity 210ms ease;
        }
      }

      .mdc-list {
        pointer-events: all;
        opacity: 1;
        transition: opacity 150ms ease-in 200ms;

        .mdc-list-item {
          color: rgba(0, 0, 0, 0.87);

          &:hover {
            background-color: orange;
            color: white;

            .mdc-menu--link {
              position: relative;
              color: white;
            }
          }
        }
      }
    }
  }
}

.routes-content-container {
  background-color: white;
  flex: 1;

  .sub-title {
    padding: 8px 16px;
    box-sizing: border-box;
    display: block;
  }

  &-footer {
    position: sticky;
    bottom: 0;
    margin-top: auto;
    padding: 2rem 1rem 1rem 1rem;
    box-sizing: border-box;
    border-top: 1px solid #eee;
    background-color: white;
    z-index: 10;
  }
}

// Temp
.dashboard-menu {
  z-index: 11 !important;
}

.dashboard-content {
  z-index: unset !important;
}

.rightdrawer {
  z-index: -1;
}

.search-box {
  position: relative;

  &.disabled {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  &.order-details {
    border-bottom: 1px solid #eee;
  }

  &-with-filter {
    position: relative;
    width: 100%;
    display: flex;
    gap: 4px;
    background: #fff;

    &.disabled {
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.04);
      }
    }

    .search-box {
      flex: 1;
    }

    .filter-clear-button {
      height: -webkit-fill-available;
    }
  }
}

.button {
  &-fit {
    width: fit-content;
  }
  &-container {
    display: flex;
    padding: 1rem;

    button {
      flex: 1;
    }
  }
}

.m {
  &-1 {
    margin: 1rem;
  }
  &b-12 {
    margin-bottom: 12px;
  }
  &r- {
    &auto {
      margin-right: auto;
    }
    &05 {
      margin-right: 0.5rem;
    }
  }
  &l-1 {
    margin-left: 1rem;
  }
}

.rmwc-data-table__content {
  width: 100%;
}
.details-table {
  &-header,
  &-row {
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-bottom: 1px solid #eee;
  }

  &-header {
    height: 50px;

    .icon-group {
      display: flex;
      align-items: center;
      margin-left: auto;
      color: rgba(0, 0, 0, 0.54);

      .material-icons {
        &:not(:last-child) {
          margin-right: 12px;
        }
      }

      .edit {
        font-size: 18px;
      }

      &.gap {
        gap: 1rem;
      }
    }
  }

  &-row {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    &__sub-header {
      width: 100%;
      margin-top: 8px;
    }

    .text-placeholder {
      display: block;
      min-height: 20px;
      height: 20px;
    }

    &__cell {
      display: flex;
      flex-direction: column;
      width: 20%;
      border-bottom: 1px solid #eee;
      margin-bottom: -1px;
      padding: 4px 0;
      min-height: 59px;
      justify-content: space-evenly;

      .primary-text {
        color: rgba(0, 0, 0, 0.54);
      }

      .secondary-text {
        min-height: 20px;
        margin-right: 12px;
        display: flex;
        align-items: center;
      }

      .mdc-select {
        margin-right: 12px;
        width: unset;
      }
    }
  }

  .project-selector {
    padding-left: 0;
  }
}

.order {
  &-details {
    background-color: #fff;

    .details-table-row__cell:last-child {
      width: 100%;
    }

    .side-bar-600 {
      .form-content-row:nth-child(14) {
        width: 100%;
      }
    }
  }

  &-menu-surface {
    z-index: 9999;
    overflow-y: scroll;
    width: max-content;
    min-width: 50ch;
  }

  &-list {
    max-width: 100%;
    width: 100%;
    border-collapse: collapse;

    .data-table-footer {
      position: sticky;
      left: 0;
      bottom: 0;
    }
  }

  &-taskjob-list {
    max-width: 100%;
    width: 100%;
    border-collapse: collapse;
  }

  &-col {
    &:before,
    &:after {
      content: ' ';
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 9999;
      width: 1px;
    }
    &:before {
      box-shadow: -1px 0 0px 1px inset #f1f1f1;
      left: -1px;
    }
    &:after {
      box-shadow: 1px 0 0px 1px inset #f1f1f1;
      right: -1px;
    }
  }
}

.products {
  &-details {
    background-color: #fff;

    .side-bar-600 {
      .form-content-row:nth-child(14) {
        width: 100%;
      }
    }

    .details-table-row {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));

      &__cell {
        width: unset;
      }
    }
  }
}

.mdc-menu-surface {
  .project {
    min-width: 50ch;
    max-width: 50ch;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0;

    .row {
      flex: 1;
      align-items: center;
      justify-content: space-between;
      display: flex;
      padding: 0.5rem;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.87);

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }

      i {
        color: rgba(0, 0, 0, 0.54);
      }
    }
    .disabled {
      color: rgba(0, 0, 0, 0.38);
      cursor: default;

      &:hover {
        background-color: inherit;
      }
    }

    .back {
      justify-content: flex-start;
      color: $dashboard-primary-light-color;

      i {
        color: $dashboard-primary-light-color;
      }
    }

    &-name {
      text-overflow: ellipsis;
      max-height: 1rem;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
    }

    &-selector-wrap {
      width: 100%;
    }
  }
}

.mdc-text-field--textarea {
  border: none !important;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.4);
  background-color: whitesmoke !important;
  resize: none !important;
  border-radius: none !important;

  &:focus-within,
  &:focus {
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0);
  }
}

.file-disabled {
  cursor: pointer;
  pointer-events: none;
  opacity: 0.4;
}

.compact-modder .file-uploaded-list-item {
  padding: 6px 12px;
}

.orders {
  .mdc-text-field:not(.mdc-text-field--disabled) {
    background-color: white;
  }

  .mdc-tab-bar {
    border-block: 4px solid #f1f1f1;
  }

  .serials-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    padding: 1rem;
  }
}

// Image list labels
.mdc-image-list__label {
  font-size: 0.8rem !important;
}

.search-with-filter {
  display: flex;
  gap: 1px;

  button {
    color: rgba(0, 0, 0, 0.54);
    background-color: #fff;
  }

  div {
    flex: 1;
  }
}

.filter {
  &-popup {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    height: 100%;

    .search-box {
      width: 100%;
    }

    .dates {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem;
    }
  }

  &-list {
    max-height: 400px;
    overflow-y: scroll;

    label {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-inline: 0.25rem;
      cursor: pointer;

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &:not(li:last-child) {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
      }
    }
    input {
      padding-right: 20px;
    }
  }
}

.max-h-screen {
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 40px);
  width: 100%;
}

.flex-row {
  display: flex;
}

.input-with-select {
  position: relative;
  flex: 1;
  display: flex;

  .field {
    flex: 1;
    input {
      padding-right: 8ch;
    }
  }

  label {
    position: absolute;
    right: 0;
    bottom: 12px;
    margin-top: auto;

    i {
      inset-block: 0;
      margin-block: auto;
    }
  }

  select {
    -webkit-font-smoothing: antialiased;
    padding-right: 2rem;
    text-align: right;
    border: none;
    font-size: 1rem;
    background: transparent;
    -webkit-appearance: none;
  }
}

.additional-quantity-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 1rem;
  background: #f3f3f3;
  border-bottom: 1px solid #8d8d8d;
}

.mdc-image-list .pdf {
  cursor: default;
  width: 80px;
  height: 80px;
  font-size: 80px;
  color: #757575;
}
