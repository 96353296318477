.Projects {
  .project-sync-dialog {
    .mdc-dialog__content {
      height: 50px;
    }
  }
}

.project-list {
  .project {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .project-color-code {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      margin-right: 12px;
      flex-shrink: 0;
    }
  }

  .data-table__row {
    height: 50px;
    padding-top: unset;
    padding-bottom: unset;

    .data-table__cell {
      &.project-code {
        width: 200px;
        flex-shrink: 0;
      }

      &.project {
        width: 100%;
      }
    }
  }

  .data-table__cell--end {
    display: flex;
    color: rgba(0, 0, 0, 0.54);

    .material-icons {
      font-size: 22px;

      &:not(:last-child) {
        margin-right: 6px;
      }
    }
  }

  // Indent
  .indent-wrap {
    position: relative;
    padding-left: 12px;

    .indent-line {
      width: 2px;
      position: relative;
      top: 8px;
      left: 0;
      bottom: 8px;
      background-color: lightgrey;
      height: 100%;

      &::before,
      &::after {
        content: '';
        position: absolute;
        left: -2px;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: lightgrey;
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }
  }
}
