@import '../../styles/_global.variables';

@import '../SideSheet/MDCSideSheet/mixins';

.mdc-side-sheet {
  width: 335px;
  z-index: 1000;
  overflow: hidden;

  &-scrim {
    z-index: 999;
    width: 100% !important;
  }

  > div {
    // margin-right: -50px;
    // padding-right: 50px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &--modal {
    top: 0;

    &.mdc-side-sheet--open {
      &:not(.mdc-side-sheet--closing) {
        ~ .mdc-side-sheet-app-content {
          margin-right: 0;
        }
      }
    }

    ~ .mdc-side-sheet-app-content {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }

  &--dismissible {
    position: fixed !important;
    top: 44px;

    &.mdc-side-sheet--open {
      &:not(.mdc-side-sheet--closing) {
        ~ .mdc-side-sheet-app-content {
          margin-right: 334px;
          width: calc(100% - 334px);
        }
      }
    }

    ~ .mdc-side-sheet-app-content {
      transition: margin-right 250ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
}

.side-sheet {
  display: none;

  &-header {
    display: flex !important;
    align-items: center;
    flex-direction: row !important;
    padding: 16px 12px 16px 16px;
    box-sizing: border-box;
    border-bottom: 1px solid #3c4044;

    i {
      margin-left: auto;
      font-size: 18px;
      padding: 4px;
      cursor: pointer;

      &:hover {
        background-color: #3c4044;
        border-radius: 50%;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }

  &-body {
    padding: 16px 16px 0 16px;
    box-sizing: border-box;

    .mdc-button {
      width: 100%;
    }

    &__item {
      margin-bottom: 16px;
      padding-top: 5px;
      margin-top: -5px;
      overflow: hidden;
    }

    .add-button {
      width: 100%;
    }

    .mdc-select,
    .mdc-text-field {
      width: 100%;
    }

    .time-group {
      @include flex-box(row);
      padding-bottom: 24px;

      .mdc-text-field__input {
        font-size: 0.8rem;
      }

      .mdc-text-field-helper-line {
        position: absolute;
        bottom: 0;
        left: 0;
      }

      .mdc-text-field {
        flex: 1;
        width: 50% !important;

        &:not(:last-child) {
          margin-right: 12px;
        }

        &:nth-child(2) {
          margin-right: 0;
        }
      }

      .flex-box-group__item {
        padding-right: 0;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &-600,
  &-840 {
    form {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      &.grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
      }

      .col-span {
        &-1 {
          grid-column: span 1 / span 1;
        }
        &-2 {
          grid-column: span 2 / span 2;
        }
      }
    }

    .form-content-row {
      position: relative;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      width: 100%;
      margin-bottom: unset;

      &.align-start {
        align-items: flex-start;
      }

      & > * {
        flex: 1;
      }

      .mdc-menu-surface--anchor {
        width: 100%;

        .mdc-text-field {
          width: inherit;
        }

        input {
          cursor: pointer !important;
        }
      }

      .unset-margin-right {
        margin-right: unset;
      }
    }
  }

  &-600 {
    width: 600px;
  }
  &-840 {
    width: 840px;
  }

  .tag-selector {
    label {
      background-color: $mdc-theme-background !important;
    }
  }
}
