﻿.hide {
  display: none !important;
}

// 1280px
.large-tablet-1280-hide {
  @media screen and (max-width: $break-large-tablet-1280) {
    display: none !important;
  }
}

// 1024px
.large-tablet-1024-hide {
  @media screen and (max-width: $break-large-tablet-1024) {
    display: none !important;
  }
}

// 840px
.tablet-840-show {
  display: none !important;

  @media screen and (max-width: $break-tablet) {
    display: block !important;
  }
}

.tablet-840-hide {
  @media screen and (max-width: $break-tablet) {
    display: none !important;
  }
}

// 600px
.small-tablet-600-show {
  display: none !important;

  @media screen and (max-width: $break-small-tablet-600) {
    display: block !important;
  }
}

.small-tablet-600-hide {
  @media screen and (max-width: $break-small-tablet-600) {
    display: none !important;
  }
}

// 720px
.small-tablet-720-show {
  display: none !important;

  @media screen and (max-width: $break-tablet-720) {
    display: block !important;
  }
}

.small-tablet-720-hide {
  @media screen and (max-width: $break-tablet-720) {
    display: none !important;
  }
}

// 480px
.phone-480-hide {
  @media screen and (max-width: $break-phone-480) {
    display: none !important;
  }
}

// 400px
.phone-400-hide {
  @media screen and (max-width: $break-phone-400) {
    display: none !important;
  }
}
