.add-user {
  display: flex;
  flex-direction: column;

  .basic-data-fields {
    display: flex;
    flex-direction: row;
    padding: 8px 16px;
    box-sizing: border-box;

    .field {
      flex: 1;

      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }
}
