@import '../../styles/_global.variables';

.ActivitySync {
  .counter {
    width: 100%;
    display: block;
    background-color: white;
    padding: 4px 16px;
    box-sizing: border-box;
    color: #717171;
    font-size: 14px;
  }

  .data-table {
    .Collapsible {
      &.active {
        cursor: pointer;
      }
    }

    &__row {
      border-bottom: 1px solid #eee;
      height: unset;

      .circular-progress-wrap {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.6);
        box-sizing: border-box;
      }

      &--title {
        position: sticky;
        background-color: white;
        z-index: 9;
        border-top: 4px solid #eee;

        .project-cell {
          margin-bottom: 0;
        }

        .flex-box-item {
          &:not(:last-child) {
            margin-right: 12px;
          }
        }

        .material-icons {
          min-width: 48px;
          display: flex;
          justify-content: center;

          &.active {
            color: #ff8c00;
          }
        }
      }
    }

    &-body {
      .data-table__row {
        &:hover {
          background-color: #eee;
        }
      }
    }
  }

  .project-cell {
    min-width: 290px;

    @media screen and (max-width: 1480px) {
      margin-bottom: 8px;
    }
  }

  .applied {
    width: 120px;
    flex-shrink: 0;
  }

  .status {
    .success {
      color: #6e9c21;
    }

    .warning {
      color: #bf9900;
    }

    .error {
      color: #a90202;
    }
  }

  .empty-cell {
    color: rgba(128, 128, 128, 0.64);
  }

  .comment {
    width: 220px;
    flex-shrink: 0;

    @media screen and (max-width: 1400px) {
      width: 180px;
    }

    .project-comment {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .person {
    min-width: 200px;
  }

  .sync-date {
    display: flex;
    align-items: center;
    max-width: 100px;

    &::after {
      content: '\e0c9';
      @extend %materialIcons;
      margin-left: 8px;
      font-size: 16px;
      margin-left: 8px;
    }
  }

  .fail-reason {
    padding: 8px;
    box-sizing: border-box;
    justify-content: center;
    font-size: 14px;
    display: flex;
    border-bottom: 1px solid #eee;
    background-color: red;
    background-color: #fbfbfb;
    color: #d50000;
  }

  // Text overflow
  .project-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 200px;
  }
}
