﻿// Dashboard size variables
$dashboard-header-height: 40px;
$dashboard-menu-width: 100px;
$dashboard-secondary-panel-width: 255px;
$dashboard-user-menu-width: $dashboard-secondary-panel-width;
$dashboard-padding: 4px;
$dashboard-right-side-bar: 860px;

// Menu item variables
$dashboard-menu-item-padding: 6px;
$dashboard-menu-item-font-size: 12px;
$dashboard-menu-item-icon-margin: 2px;

.site-title {
  color: white;
}

.dashboard-header {
  width: 100% !important;
  z-index: 100;
  position: sticky;
  top: 0;

  @include mediaQuery(tablet-840) {
    position: fixed !important;
    z-index: 99999;
  }

  &__title {
    font-weight: 400 !important;
    font-size: 16px !important;
  }

  &__row {
    box-sizing: border-box;
    color: white;
    display: flex;
    align-items: center;
    background-color: $dashboard-primary-color !important;
    height: $dashboard-header-height !important;
    min-height: $dashboard-header-height !important;
    padding-left: 16px;

    @include mediaQuery(tablet-840) {
      padding: 0 16px !important;
    }

    .mdc-toolbar__section--align-end {
      margin-left: auto;
    }
  }

  &__section {
    align-items: center !important;
  }

  &__menu-icon {
    width: 32px;
    padding: 0 !important;
  }
}

.drawer-container {
  display: flex;
  flex-direction: row;
  position: relative;
  max-width: 100vw;

  @include mediaQuery(tablet-840) {
    top: 40px;
    height: calc(100vh - 40px);
  }

  .dashboard-menu {
    &--main {
      position: sticky;
      top: 40px;
      flex-shrink: 0;
      width: $dashboard-menu-width;
      background-color: $dashboard-primary-light-color;
      padding: $dashboard-padding 0;
      box-sizing: border-box;
      z-index: 50;
      height: calc(100vh - 40px);

      .avatar {
        width: 50px;
        height: 50px;
        margin-right: unset;
        margin-bottom: 8px;
      }

      .mdc-list-item {
        &::before {
          width: 0;
        }
      }

      .menu-item {
        position: relative;
        cursor: pointer;
        height: 50px;

        a {
          i {
            &.fa {
              font-size: 20px !important;
            }
          }
        }

        > a * {
          pointer-events: none;
        }

        &--last {
          position: absolute !important;
          bottom: 16px;
          left: 0;
          right: 0;
          height: auto;

          .person-avatar--wrap {
            width: 50px;
            height: 50px;
            margin-bottom: 5px;

            .circle-avatar {
              background-color: #e0e0e0;
              width: inherit;
              height: inherit;
              border-radius: 50%;
              text-align: center;
              @extend %verticalCenter;
              color: rgba(0, 0, 0, 0.54);
              font-weight: 500;
            }

            .avatar-img {
              width: inherit;
              height: inherit;
              display: block;
              background-size: cover;
              background-position: center center;
            }
          }
        }

        &--active {
          &::before {
            @extend %menuItemHover;
            visibility: visible;
          }
        }

        &--selected {
          background-color: $dashboard-primary-color;
        }

        .menu-item__inner-wrap {
          @extend %horizontalCenter;
          padding: #{$dashboard-menu-item-padding} 0;
          color: white;

          i {
            margin-bottom: $dashboard-menu-item-icon-margin;

            &.fa {
              font-size: 24px;
            }
          }

          .menu-item--text {
            font-size: $dashboard-menu-item-font-size;
            line-height: $dashboard-menu-item-font-size;
            text-align: center;
          }

          &:hover {
            background-color: $dashboard-primary-color;
          }
        }
      }
    }

    .mobile-sub-menu {
      display: none;
    }

    &--sub {
      height: calc(100vh - 40px);
      position: sticky;
      top: 40px;
      background-color: white;
      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

      @media screen and (max-width: 1440px) {
        position: fixed;
        top: unset;
      }

      .mdc-typography {
        &--title {
          color: $dashboard-primary-color !important;
        }
      }

      &.mdc-drawer--modal {
        left: 100px;
        z-index: 40;
        height: calc(100% - 40px);

        + .mdc-drawer-scrim {
          top: 40px;
          left: 100px;
          z-index: 30;
        }
      }

      &.mdc-drawer--temporary {
        top: $dashboard-header-height;
        left: $dashboard-menu-width;
        height: calc(100% - 40px);
        z-index: 999;

        .mdc-drawer__drawer {
          max-width: $dashboard-secondary-panel-width;
        }

        @media screen and (min-width: #{$break-large-2 + 1}) {
          pointer-events: all;
          position: relative;
          width: $dashboard-secondary-panel-width;
          top: 0;
          left: 0;
          height: 100%;

          .mdc-drawer__drawer {
            transform: none;
            width: 100%;
            max-width: $dashboard-secondary-panel-width;
          }
        }
      }

      &-content {
        width: $dashboard-secondary-panel-width;
        padding: 5px 0 0 0;
        box-sizing: border-box;
        display: inline-flex;
        flex-direction: column;
        position: relative;

        .user-menu-sizes {
          width: 100%;
          box-shadow: none;
          margin-top: auto;

          .mdc-list {
            padding-bottom: 0;
          }

          .user {
            @extend %clearfix;
            display: flex !important;
            justify-content: flex-start;
            @extend %verticalCenterRow;
            border-top: 1px solid #eee;

            .user-image,
            .user-name {
              float: left;
            }

            .user-image {
              margin-right: 8px;
            }
          }
        }

        .mdc-list-item {
          border-bottom: 1px solid;
          border-bottom-color: rgba(0, 0, 0, 0.12);
          font-size: 0.875rem;
          font-weight: 500;
          height: 50px;
          margin: 0 !important;
          padding: 0;
          border-radius: 0;
          box-sizing: border-box;

          &.active {
            background-color: rgba(0, 0, 0, 0.05);
          }
        }

        .mdc-list-group__subheader {
          font-size: 0.875rem;
          line-height: 1.5rem;
          font-weight: 500;
          border-bottom: 1px solid;
          border-bottom-color: rgba(0, 0, 0, 0.12);
          height: 50px;
          @extend %verticalCenter;

          &::before {
            display: none;
          }
        }

        a {
          width: 100%;
          height: 100%;
          padding: 12px 16px;
          position: relative;
          margin-right: 0;
          box-sizing: border-box;
        }

        .panel {
          display: none;

          &.active {
            display: block;

            .group-header {
              cursor: default;
            }
          }
        }
      }
    }
  }
}

.drawer-app-content {
  flex: 1;
  padding: 0 4px;
  box-sizing: border-box;
  position: relative;
  border-style: solid;
  border-color: #f1f1f1;
  overflow-x: hidden;

  > div {
    width: 100%;
    max-width: 100%;

    > div {
      display: flex;
      flex-direction: column;
    }
  }
}

.side-sheet--custom-width {
  width: 800px !important;
  border: unset !important;
}
