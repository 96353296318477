.order-list {
  flex: 1;
  max-width: calc(100vw - 109px);
  .row {
    cursor: pointer;

    .notes {
      max-width: '40ch';
      text-overflow: 'ellipsis';
      overflow: 'hidden';
    }
  }
}

.border-right {
  border-right: 1px solid #333;
}