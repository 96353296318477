@import '../../../../styles/_global.variables';

.project-details {
  .indent {
    &.indent--begin {
      &.indent--end {
        top: 6px;
      }
    }
  }

  .data-table {
    &__cell {
      align-items: center;
      &.project-name {
        max-width: 800px;
        flex-shrink: 0;
        flex-direction: row;
        justify-content: flex-start;
        padding-right: 12px;
        align-self: normal;
      }

      .project-name {
        align-self: center;
      }
    }

    &__row {
      &--title {
        .project-name {
          align-self: center;
        }
      }
    }
  }

  .project {
    width: 33.33%;
    flex-shrink: 0;
  }

  .project-color-code {
    margin-right: 12px;
    width: 15px !important;
    height: 15px !important;
    border-radius: 100%;
    flex-shrink: 0;
  }

  @for $i from 1 through 6 {
    .sub-project--indent-#{$i} {
      padding-left: #{($i) * 12px};
      @include box-sizing;
    }
  }
}

.project-add-or-edit-form {
  width: 450px;
}
