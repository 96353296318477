@import '../../styles/_global.variables';

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

.mdc-circular-progress {
  position: relative;
  width: 35px;
  height: 35px;
  animation: rotate 2s linear infinite;

  &__path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }
}

.circular-progress-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  cursor: default;
  border-radius: 8px;
  padding: 16px 0;

  &.authcallback-progress {
    height: unset !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    background-color: unset !important;
  }

  &.full-height {
    position: fixed;
    top: 40px;
    left: 355px;
    right: 0;
    bottom: 0;
    padding: 0;
    background-color: rgba(230, 230, 230, 0.44);
    border-radius: 0;

    @media screen and (max-width: 1480px) {
      left: 100px;
    }

    &.absolute {
      position: absolute;
      left: 0;
    }
  }
}
